import { formatLabel } from "./format-label";

// biome-ignore lint/suspicious/noExplicitAny: <fix later>
export function enumToArray(enumObject: any) {
  return Object.keys(enumObject)
    .filter(key => Number.isNaN(Number(key)))
    .map(key => ({
      value: key,
      label: formatLabel(key)
    }));
}
