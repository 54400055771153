import { usersTableActionAllowedLists } from "@/config/access";
import { useAppStore } from "@/store";
import { UserRole } from "@watt/db/src";

export function useUser() {
  const profileData = useAppStore.getState().profileData;

  const isAdmin =
    profileData?.role === UserRole.ADMIN ||
    usersTableActionAllowedLists.includes(
      useAppStore.getState().profileData?.email || ""
    );

  const isSalesAgent = profileData?.role === UserRole.SALES_AGENT;

  return { isAdmin, userId: profileData?.userId, isSalesAgent };
}
