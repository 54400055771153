"use client";

import { trpcClient } from "@/utils/api";
import { type QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import { httpBatchLink, loggerLink } from "@trpc/client";
import type { AppRouter } from "@watt/api/src";
import { transformer } from "@watt/api/src/transformer";
import { env } from "@watt/common/src/config/env";
import { useState } from "react";
import { TRPC_LOGS, tRPCBaseUrl } from "./config";
import { createQueryClient } from "./query-client";

type TRPCReactProviderProps = {
  readonlyHeaders?: Headers;
  showDevTools?: boolean;
  children: React.ReactNode;
};

let clientQueryClientSingleton: QueryClient | undefined = undefined;

const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  }

  // Browser: use singleton pattern to keep the same query client
  if (!clientQueryClientSingleton) {
    clientQueryClientSingleton = createQueryClient();
  }
  return clientQueryClientSingleton;
};

/**
 * This is the client-side tRPC client for hook usage
 * via trpcClient.books.useQuery(...)
 * @param props
 * @returns
 */
export function TRPCReactProvider({
  children,
  readonlyHeaders,
  showDevTools
}: TRPCReactProviderProps) {
  const queryClient = getQueryClient();

  const [client] = useState(() =>
    trpcClient.createClient({
      links: [
        loggerLink<AppRouter>({
          enabled: opts =>
            (env.NODE_ENV === "development" ||
              (opts.direction === "down" && opts.result instanceof Error)) &&
            TRPC_LOGS
        }),
        httpBatchLink({
          transformer,
          url: `${tRPCBaseUrl()}/api/trpc`,
          headers() {
            const headers = new Headers();
            if (readonlyHeaders) {
              for (const [key, value] of readonlyHeaders.entries()) {
                headers.append(key, value);
              }
            }
            headers.set("x-trpc-source", "nextjs-react");
            return headers;
          }
        })
      ]
    })
  );

  return (
    <>
      <trpcClient.Provider client={client} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryStreamedHydration transformer={transformer}>
            {children}
          </ReactQueryStreamedHydration>
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        </QueryClientProvider>
      </trpcClient.Provider>
      {/* Hide until we have a use case for it */}
      {/* {showDevTools && <TRPCDevTools />} */}
    </>
  );
}
