// This file was generated by a custom prisma generator, do not edit manually.

export const BusinessType = {
  LTD: "LTD",
  PLC: "PLC",
  LLP: "LLP",
  CHARITY: "CHARITY",
  SOLE_TRADER: "SOLE_TRADER"
} as const;

export type BusinessType = (typeof BusinessType)[keyof typeof BusinessType];

export const PaymentMethod = {
  UNKNOWN: "UNKNOWN",
  NO_PAYMENT_DETAILS: "NO_PAYMENT_DETAILS",
  MONTHLY_DIRECT_DEBIT: "MONTHLY_DIRECT_DEBIT",
  QUARTERLY_DIRECT_DEBIT: "QUARTERLY_DIRECT_DEBIT",
  CASH_OR_CHEQUE: "CASH_OR_CHEQUE",
  EXISTING_WITH_SUPPLIER: "EXISTING_WITH_SUPPLIER",
  BACS: "BACS"
} as const;

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

export const Salutation = {
  MR: "MR",
  MRS: "MRS",
  MS: "MS",
  DR: "DR",
  PROF: "PROF"
} as const;

export type Salutation = (typeof Salutation)[keyof typeof Salutation];

export const UtilityType = {
  ELECTRICITY: "ELECTRICITY",
  GAS: "GAS",
  WATER: "WATER",
  TELECOM: "TELECOM",
  INTERNET: "INTERNET",
  SOLAR: "SOLAR",
  BATTERY_STORAGE: "BATTERY_STORAGE",
  ENERGY_AUDIT: "ENERGY_AUDIT",
  FINANCE: "FINANCE",
  BUSINESS_INSURANCE: "BUSINESS_INSURANCE"
} as const;

export type UtilityType = (typeof UtilityType)[keyof typeof UtilityType];

export const QuoteStatus = {
  GENERATED: "GENERATED",
  SENDING: "SENDING",
  PROCESSED: "PROCESSED",
  DELIVERED: "DELIVERED",
  OPEN: "OPEN",
  CLICK: "CLICK",
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  DROPPED: "DROPPED",
  DEFERRED: "DEFERRED",
  BOUNCE: "BOUNCE",
  BLOCKED: "BLOCKED",
  SPAMREPORT: "SPAMREPORT",
  UNSUBSCRIBE: "UNSUBSCRIBE",
  GROUP_UNSUBSCRIBE: "GROUP_UNSUBSCRIBE",
  GROUP_RESUBSCRIBE: "GROUP_RESUBSCRIBE",
  EXPIRED: "EXPIRED",
  ERROR: "ERROR"
} as const;

export type QuoteStatus = (typeof QuoteStatus)[keyof typeof QuoteStatus];

export const QuoteType = {
  UD: "UD",
  CUSTOM: "CUSTOM"
} as const;

export type QuoteType = (typeof QuoteType)[keyof typeof QuoteType];

export const UserRole = {
  DEVELOPER: "DEVELOPER",
  DIRECTOR: "DIRECTOR",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  SALES_AGENT: "SALES_AGENT",
  LEAD_GENERATOR: "LEAD_GENERATOR",
  COMPLIANCE: "COMPLIANCE",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
  FINANCE: "FINANCE",
  SYSTEM: "SYSTEM"
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

export const ActivityType = {
  COMPANY: "COMPANY",
  SITE: "SITE",
  METER: "METER",
  DEAL: "DEAL",
  PAYMENT: "PAYMENT",
  POOL_AND_SEGMENT: "POOL_AND_SEGMENT",
  DOCUMENT: "DOCUMENT",
  NOTE: "NOTE",
  INTERACTION: "INTERACTION",
  CALLBACK: "CALLBACK",
  PROFILE: "PROFILE"
} as const;

export type ActivityType = (typeof ActivityType)[keyof typeof ActivityType];

export const HuntGroup = {
  SOUTH_AFRICA_EON: "SOUTH_AFRICA_EON",
  SOUTH_AFRICA_MIXED_POOL: "SOUTH_AFRICA_MIXED_POOL",
  SOUTH_AFRICA_MIXED_POOL_2: "SOUTH_AFRICA_MIXED_POOL_2",
  SOUTH_AFRICA_OPUS_IN_WINDOW: "SOUTH_AFRICA_OPUS_IN_WINDOW",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE"
} as const;

export type HuntGroup = (typeof HuntGroup)[keyof typeof HuntGroup];

export const SiteStatus = {
  ACTIVE: "ACTIVE",
  CEASED: "CEASED"
} as const;

export type SiteStatus = (typeof SiteStatus)[keyof typeof SiteStatus];

export const NoteProfileType = {
  COMPANY: "COMPANY",
  SITE: "SITE"
} as const;

export type NoteProfileType =
  (typeof NoteProfileType)[keyof typeof NoteProfileType];

export const ProviderStatus = {
  REVIEW_REQUIRED: "REVIEW_REQUIRED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETE: "COMPLETE"
} as const;

export type ProviderStatus =
  (typeof ProviderStatus)[keyof typeof ProviderStatus];

export const EmailTransactionEvents = {
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  DROPPED: "DROPPED",
  DELIVERED: "DELIVERED",
  DEFERRED: "DEFERRED",
  BOUNCE: "BOUNCE",
  BLOCKED: "BLOCKED",
  OPEN: "OPEN",
  CLICK: "CLICK",
  SPAMREPORT: "SPAMREPORT",
  UNSUBSCRIBE: "UNSUBSCRIBE",
  GROUP_UNSUBSCRIBE: "GROUP_UNSUBSCRIBE",
  GROUP_RESUBSCRIBE: "GROUP_RESUBSCRIBE"
} as const;

export type EmailTransactionEvents =
  (typeof EmailTransactionEvents)[keyof typeof EmailTransactionEvents];

export const CallStatus = {
  QUEUED: "QUEUED",
  RINGING: "RINGING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  BUSY: "BUSY",
  FAILED: "FAILED",
  NO_ANSWER: "NO_ANSWER",
  CANCELED: "CANCELED"
} as const;

export type CallStatus = (typeof CallStatus)[keyof typeof CallStatus];

export const AgentStatus = {
  IDLE: "IDLE",
  ON_HOLD: "ON_HOLD",
  OUTGOING: "OUTGOING",
  INCOMING: "INCOMING",
  RINGING: "RINGING",
  OFFLINE: "OFFLINE"
} as const;

export type AgentStatus = (typeof AgentStatus)[keyof typeof AgentStatus];

export const AbandonCallStatus = {
  UNANSWERED: "UNANSWERED",
  QUEUE_DROPOUT: "QUEUE_DROPOUT",
  MISSED_CALL: "MISSED_CALL",
  REDIRECTED_TO_QUEUE: "REDIRECTED_TO_QUEUE"
} as const;

export type AbandonCallStatus =
  (typeof AbandonCallStatus)[keyof typeof AbandonCallStatus];

export const DealStatus = {
  DEAL_MADE: "DEAL_MADE"
} as const;

export type DealStatus = (typeof DealStatus)[keyof typeof DealStatus];

export const ContractType = {
  VERBAL: "VERBAL",
  WRITTEN: "WRITTEN"
} as const;

export type ContractType = (typeof ContractType)[keyof typeof ContractType];

export const ContractStatus = {
  GENERATED: "GENERATED",
  SENT: "SENT",
  SIGNED: "SIGNED",
  PROCESSING: "PROCESSING"
} as const;

export type ContractStatus =
  (typeof ContractStatus)[keyof typeof ContractStatus];

export const CallbackStatus = {
  SCHEDULED: "SCHEDULED",
  CANCELLED: "CANCELLED",
  COMPLETE: "COMPLETE"
} as const;

export type CallbackStatus =
  (typeof CallbackStatus)[keyof typeof CallbackStatus];

export const NotificationType = {
  TODAY_CALLBACK_NOTIFICATION: "TODAY_CALLBACK_NOTIFICATION",
  UPCOMING_CALLBACK_NOTIFICATION: "UPCOMING_CALLBACK_NOTIFICATION",
  VERIFY_CALLBACK_NOTIFICATION: "VERIFY_CALLBACK_NOTIFICATION",
  OVERDUE_CALLBACK_NOTIFICATION: "OVERDUE_CALLBACK_NOTIFICATION",
  ANNOUNCEMENT_NOTIFICATION: "ANNOUNCEMENT_NOTIFICATION"
} as const;

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

export const PhoneNumberType = {
  MOBILE: "MOBILE",
  WORK: "WORK",
  HOME: "HOME",
  OTHER: "OTHER"
} as const;

export type PhoneNumberType =
  (typeof PhoneNumberType)[keyof typeof PhoneNumberType];

export const SaleType = {
  ACQUISITION: "ACQUISITION",
  RENEWAL: "RENEWAL"
} as const;

export type SaleType = (typeof SaleType)[keyof typeof SaleType];
