"use client";

import { useUser } from "@/hooks/use-user";
import { NovuProvider } from "@novu/react";
import { isLocalEnvironment } from "@watt/common";
import { env } from "@watt/common/src/config/env";
import { getBaseUrl } from "@watt/common/src/utils/get-base-url";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

const isLocal = isLocalEnvironment();
const baseUrl = getBaseUrl();
/**
 * PostHog provider, tracks pageviews and captures events by default.
 * If you need to capture a custom event, you can do so by calling posthog.capture() in a react component.
 * `posthog.capture('my event', { property: 'value' })`
 */
export function PHProvider({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    if (isLocal) {
      // Quote this out if you wish to run posthog locally
      return;
    }

    posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: !isLocal ? `${baseUrl}/ingest` : env.NEXT_PUBLIC_POSTHOG_HOST,
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      loaded: posthog => {
        if (env.NODE_ENV === "development") {
          posthog.debug(); // debug mode in development
        }
      },
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true // Enable pageleave capture
    });
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export function ReactNovuProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { userId } = useUser();

  if (!userId) {
    return children;
  }

  return (
    <NovuProvider
      applicationIdentifier={env.NEXT_PUBLIC_NOVU_APPLICATION_IDENTIFIER!}
      subscriberId={userId}
    >
      {children}
    </NovuProvider>
  );
}
