import { BusinessType } from "@prisma/client";
import {
  CHARITABLE_ENTITY_REGEX,
  COMPANY_NUMBER_REGEX,
  EXPERIAN_UNIQUE_IDENTIFIER_REGEX,
  type Icon,
  Icons
} from "@watt/common";

export const BUSINESS_TYPE_MAP = {
  LTD: 1,
  CHARITY: 2,
  SOLE_TRADER: 3,
  PLC: 4,
  LLP: 5
} as const;

type BusinessTypeMap = {
  [key in BusinessType]: {
    id: number;
    title: string;
    Icon: Icon;
  };
};

const businessTypeMap: BusinessTypeMap = {
  [BusinessType.LTD]: {
    id: 1,
    title: "Limited company",
    Icon: Icons.building2
  },
  // Update Company Type mapping to be inclusive of both Private/Public Limited
  // since Experian API only returns Limited/Unlimited variants
  [BusinessType.CHARITY]: {
    id: 2,
    title: "Charity",
    Icon: Icons.charity
  },
  [BusinessType.SOLE_TRADER]: {
    id: 3,
    title: "Sole trader",
    Icon: Icons.soletrader
  },
  [BusinessType.PLC]: {
    id: 4,
    title: "Public limited company",
    Icon: Icons.building2
  },
  [BusinessType.LLP]: {
    id: 5,
    title: "Limited liability partnership",
    Icon: Icons.building2
  }
};

export function getBusinessType(businessType: BusinessType) {
  if (!businessTypeMap[businessType]) {
    throw new Error(`Utility ${businessType} not found`);
  }

  return businessTypeMap[businessType];
}

export function getRegistrationNumberLabel(
  businessType: BusinessType
): string | null {
  switch (businessType) {
    case BusinessType.LTD:
      return "Company Registration Number";
    case BusinessType.CHARITY:
      return "Charity Number";
    case BusinessType.SOLE_TRADER:
      return null;
    default:
      return "Company Registration Number";
  }
}

export function getBusinessTypeByReferenceNumber(businessReference: string) {
  // Check if businessReference is a charitable entity
  if (CHARITABLE_ENTITY_REGEX.test(businessReference)) {
    return getBusinessType(BusinessType.CHARITY);
  }

  // Check if businessReference is a unique identifier
  if (EXPERIAN_UNIQUE_IDENTIFIER_REGEX.test(businessReference)) {
    // Determine the specific business type based on unique identifier pattern
    // Example logic (needs to be adjusted according to your specific business logic)
    // For now, just returning SOLE_TRADER as an example
    return getBusinessType(BusinessType.SOLE_TRADER);
  }

  // Check if businessReference is a company number
  if (COMPANY_NUMBER_REGEX.test(businessReference)) {
    // Determine the specific business type based on company number pattern
    // Example logic (needs to be adjusted according to your specific business logic)
    // For now, just returning LTD as an example
    return getBusinessType(BusinessType.LTD);
  }

  // If none of the patterns match, throw an error or return a default type
  throw new Error(`Invalid business reference: ${businessReference}`);
}
