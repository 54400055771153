import { UtilityType } from "@prisma/client";
import { type Icon, Icons } from "@watt/common";

export const UTILITIES_MAP = {
  ELECTRIC: 1,
  GAS: 2,
  WATER: 3,
  TELEPHONE: 4,
  INTERNET: 5
} as const;

type UtilityMap = {
  [key in UtilityType]: {
    id: number;
    title: string;
    Icon: Icon;
    color: string;
  };
};

const utilityMap: UtilityMap = {
  [UtilityType.ELECTRICITY]: {
    id: 1,
    title: "Electricity",
    Icon: Icons.electricity,
    color: "#FECE39"
  },
  [UtilityType.GAS]: {
    id: 2,
    title: "Gas",
    Icon: Icons.gas,
    color: "#D6463B"
  },
  [UtilityType.WATER]: {
    id: 3,
    title: "Water",
    Icon: Icons.water,
    color: "#0586CB"
  },
  [UtilityType.TELECOM]: {
    id: 4,
    title: "Phone",
    Icon: Icons.phone,
    color: "#FE8946"
  },
  [UtilityType.INTERNET]: {
    id: 5,
    title: "Internet",
    Icon: Icons.internet,
    color: "#24C1B4"
  },
  [UtilityType.SOLAR]: {
    id: 6,
    title: "Solar",
    Icon: Icons.solar,
    color: "#FE9554"
  },
  [UtilityType.BATTERY_STORAGE]: {
    id: 7,
    title: "Battery",
    Icon: Icons.battery,
    color: "#94BF22"
  },
  [UtilityType.ENERGY_AUDIT]: {
    id: 8,
    title: "Energy Audit",
    Icon: Icons.energyAudit,
    color: "#FE8946"
  },
  [UtilityType.FINANCE]: {
    id: 9,
    title: "Finance",
    Icon: Icons.finance,
    color: "#A586CB"
  },
  [UtilityType.BUSINESS_INSURANCE]: {
    id: 10,
    title: "Business Insurance",
    Icon: Icons.businessInsurance,
    color: "#E0D840"
  }
};

export function getUtility(utilityType: UtilityType) {
  if (!utilityMap[utilityType]) {
    throw new Error(`Utility ${utilityType} not found`);
  }

  return utilityMap[utilityType];
}
