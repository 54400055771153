import type {
  CompaniesWith_Add_Con_Sit,
  LOA_All_Output,
  PeopleWith_Com_Add,
  SitesWith_Add_Com
} from "@watt/api/src";
import { env } from "@watt/common/src/config/env";
import type { ProfileModel } from "@watt/db/prisma/zod";
import type { z } from "zod";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { DataTableOption } from "@/components/data-table/data-table-faceted-filter";

import { createSelectors } from "./create-selectors";

export { shallow } from "zustand/shallow";

type State = {
  allSalesAgents: z.infer<typeof ProfileModel>[];
  allDirectDials: DataTableOption[];
  profileData: z.infer<typeof ProfileModel> | null;
  companies: CompaniesWith_Add_Con_Sit;
  sites: SitesWith_Add_Com;
  people: PeopleWith_Com_Add;
  loas: LOA_All_Output;
};

type Actions = {
  setAllSalesAgents: (allProfilesData: z.infer<typeof ProfileModel>[]) => void;
  setAllDirectDials: (allDirectDials: DataTableOption[]) => void;
  setProfileData: (user: z.infer<typeof ProfileModel> | null) => void;
  setCompanies: (company: CompaniesWith_Add_Con_Sit) => void;
  setSites: (sites: SitesWith_Add_Com) => void;
  setPeople: (company: PeopleWith_Com_Add) => void;
  setLoas: (loas: LOA_All_Output) => void;
};

type Store = State & Actions;

export const useAppStore = createSelectors(
  createWithEqualityFn<Store>()(
    persist(
      immer(
        devtools(
          set => ({
            allSalesAgents: [],
            setAllSalesAgents: allSalesAgents => {
              set({ allSalesAgents });
            },
            allDirectDials: [],
            setAllDirectDials: allDirectDials => {
              set({ allDirectDials });
            },
            profileData: null,
            setProfileData: profileData => {
              set({ profileData });
            },
            companies: [],
            setCompanies: companies => {
              set({ companies });
            },
            sites: [],
            setSites: sites => {
              set({ sites });
            },
            people: [],
            setPeople: people => {
              set({ people });
            },
            loas: [],
            setLoas: loas => {
              set({ loas });
            }
          }),
          {
            enabled:
              env.NODE_ENV === "development" && typeof window !== "undefined",
            name: "App Store"
          }
        )
      ),
      {
        name: "App Store"
      }
    ),
    shallow
  )
);
