import type { StoreApi, UseBoundStore } from "zustand";

type ExtractState<S> = S extends { getState: () => infer T } ? T : never;

type SelectorHooks<T> = {
  [K in keyof T]: () => T[K];
};

type WithSelectors<S extends UseBoundStore<StoreApi<object>>> = S & {
  use: SelectorHooks<ExtractState<S>>;
};

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  store: S
): WithSelectors<S> => {
  const enhancedStore = store as WithSelectors<S>;
  enhancedStore.use = {} as SelectorHooks<ExtractState<S>>;

  const stateKeys = Object.keys(store.getState()) as Array<
    keyof ExtractState<S>
  >;

  for (const key of stateKeys) {
    enhancedStore.use[key] = () => store(s => s[key as keyof typeof s]);
  }

  return enhancedStore;
};
