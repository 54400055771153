import { PaymentMethod } from "@prisma/client";

type PaymentMethodeMap = Record<PaymentMethod, { id: number; title: string }>;

const paymentMethodMap: PaymentMethodeMap = {
  [PaymentMethod.UNKNOWN]: {
    id: 0,
    title: "Unknown"
  },
  [PaymentMethod.NO_PAYMENT_DETAILS]: {
    id: 1,
    title: "No payment details"
  },
  [PaymentMethod.MONTHLY_DIRECT_DEBIT]: {
    id: 2,
    title: "Bank account (Monthly)"
  },
  [PaymentMethod.QUARTERLY_DIRECT_DEBIT]: {
    id: 3,
    title: "Bank account (Quarterly)"
  },
  [PaymentMethod.CASH_OR_CHEQUE]: {
    id: 4,
    title: "Cash cheque"
  },
  [PaymentMethod.EXISTING_WITH_SUPPLIER]: {
    id: 5,
    title: "Existing details"
  },
  [PaymentMethod.BACS]: {
    id: 6,
    title: "BACS"
  }
};

export function getPaymentMethod(paymentMethod: PaymentMethod) {
  if (!paymentMethodMap[paymentMethod]) {
    throw new Error(`Payment method ${paymentMethod} not found`);
  }

  return paymentMethodMap[paymentMethod];
}

export function getPaymentMethodByID(id: number) {
  const paymentMethod = (Object.entries(paymentMethodMap).find(
    ([, details]) => details.id === id
  ) || [PaymentMethod.UNKNOWN])[0] as PaymentMethod;

  return paymentMethod;
}
