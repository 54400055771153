import { env } from "../config/env";

export function isProductionEnvironment(): boolean {
  return process.env.VERCEL_ENV === "production";
}

// Treat UAT as pre-production
export function isStagingEnvironment(): boolean {
  return (
    env.NEXT_PUBLIC_ENVIRONMENT === "develop" ||
    env.NEXT_PUBLIC_ENVIRONMENT === "qat"
  );
}

export function isLocalEnvironment(): boolean {
  return process.env.NODE_ENV === "development";
}
